import React, { useContext } from "react"

// Context
import { DataContext } from "../../context/data"

export default function Main({ children }) {
  const { data, setData } = useContext(DataContext)
    return (
      <>
      <div
        id="main"
        onMouseMove={(e) => {
          // Mouse move effect
          const cursor = document.querySelector(".cursor");
          cursor.style.left = `${e.clientX}px`;
          cursor.style.top = `${e.clientY}px`;
          cursor.classList.remove("hide");
        }}
        // Click effect
        onMouseDown={() => {
          const cursor = document.querySelector(".cursor");
          cursor.classList.add("clickDown");
        }}
        onMouseUp={() => {
          const cursor = document.querySelector(".cursor");
          cursor.classList.add("click");
          cursor.classList.remove("clickDown");
          setTimeout(() => {
            cursor.classList.remove("click");
          }, 300);
        }}
        // Enter or Leave Window effect
        onMouseEnter={() => {
          const cursor = document.querySelector(".cursor");
          cursor.classList.remove("hide");
        }}
        onMouseLeave={() => {
          const cursor = document.querySelector(".cursor");
          cursor.classList.add("hide");
        }}
  
        // Passes on the children elements
      >
        {children}
      </div>
      </>
    );
  }
  