import React from "react"

import Image from "../../image/image"

// Gatsby
import { navigate } from "gatsby"

export default function ThankYou(props) {
  const { page, setOpenForm } = props
  const goTo = url => {
    navigate(url)
  }

  return (
    <>
      <div
        className={page === "thank-you" ? "thank-you show" : "thank-you hide"}
      >
        <div className={page === "thank-you" ? "text show" : "text hide"}>
          <div className="header">
            <h2 className="thank-you-header">I'll get back to you soon.</h2>
            <Image
              src="profile-background.jpg"
              alt="Profile picture"
              title="Profile picture of Johannes Eklund"
            />
          </div>
          <div className="body">
            <h3>While you wait..</h3>
            <div className="text" onClick={() => goTo("/code/")}>
              <b>Code & softwares</b>
              <br />
            </div>
            <div className="text" onClick={() => goTo("/blog/")}>
              <b>Blog</b>
              <br />
              Learn more about digital marketing, SEO, and conversion
              optimization for a better performing digital business.
            </div>
            <div className="text" onClick={() => goTo("/music/")}>
              <b>Music</b>
              <br />A music collection of all the mixes and songs I listen too
              while I work.
            </div>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setOpenForm(false)}>
        <Image src="close.svg" alt="Close window" title="Close window" />
      </div>
    </>
  )
}
