import React from "react"
import { navigate } from "gatsby"

import firebase from "firebase/app"
import "./firebase-msg.scss"

// Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBcCO_Oq7IKqxjwtWDhPjR6R8X4fROStEM",
  authDomain: "personal-website-9cd0a.firebaseapp.com",
  projectId: "personal-website-9cd0a",
  storageBucket: "personal-website-9cd0a.appspot.com",
  messagingSenderId: "500982986074",
  appId: "1:500982986074:web:a501e2de854ce79e6f2f63",
  measurementId: "G-RRXH4FLVSF",
}
firebase.initializeApp(firebaseConfig)

export default function FirebaseMessage() {
  const [show, setShow] = React.useState(false)
  const [message, setMessage] = React.useState({
    title: "",
    description: "",
    image: "",
    url: "",
  })
  React.useEffect(() => {
    import("firebase/messaging").then(() => {
      // Loads the messaging
      const messaging = firebase.messaging()

      // Gets the payload on message
      onMessageListener(messaging)
        .then(payload => {
          console.log(payload)
          setMessage({
            title: payload.notification.title,
            description: payload.notification.body,
            image: payload.notification.image,
            url: payload.data.url,
          })
          setShow(true)
          console.log(payload)
        })
        .catch(err => console.log("failed: ", err))
    })
  }, [])

  const goTo = url => {
    navigate(url)
    window.scrollTo({ top: 0, behavior: "smooth" })
    // Fix bug with cursor disappearing when changing page
    const cursor = document.querySelector(".cursor")
    cursor.classList.remove("hide")
  }

  return (
    <div id="notification" className={show ? "show" : "hide"}>
      {message.title.length > 0 ? (
        <>
          <div className="text">
            <div className="title">{message.title}</div>
            <div className="description">{message.description}</div>
            {message.url ? <button onClick={() => goTo(message.url)}>Read more</button> : null}
          </div>
          {message.image ? <img src={message.image} alt={message.title} title={message.title} /> : null}
        </>
      ) : null}
    </div>
  )
}

export function FirebaseMessageCTA({ object }) {
  const [jsx, setJsx] = React.useState(null)
  React.useEffect(() => {
    import("firebase/messaging").then(() => {
      if (firebase.messaging.isSupported()) {
        setJsx(
          <b>
            {object.description}
            <br />
            <a onClick={authorizeFirebaseMessage}>{object.cta}</a>
          </b>
        )
      }
    })
  }, [])
  return jsx
}

export const authorizeFirebaseMessage = () =>
  import("firebase/messaging").then(() => {
    const messaging = firebase.messaging()
    getToken(messaging)
  })

const getToken = messaging => {
  return messaging
    .getToken({
      vapidKey:
        "BC7PYObEmu0RJg7iX8X7lDdBStwTdBS2HcYYGH9bdcuLVu2Vpz-AiGerQ-TBN89TscfRm7AkiMqZUnr0vDZ8ciE",
    })
    .then(currentToken => {
      if (currentToken) {
        console.log("current token for client: ", currentToken)
        return true
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        )
        return false
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err)
    })
}

const onMessageListener = messaging =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload)
    })
  })
