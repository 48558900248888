import React from "react"

export default function ConnectionFailed({ error, setOpenForm }) {
  return (
    <>
      <div id="connection-failed" className={error ? "show" : "hide"}>
        <div className="text">
          <h2>Connection failed</h2>
          <h3>
            Something went wrong.
            <br />
            Please email me instead with your request.
          </h3>
          <h4>hello (at) johanneseklund.com</h4>
        </div>
        <div className="button-wrapper">
          <div className="button" onClick={() => setOpenForm(false)}>
            Close Window
          </div>
        </div>
      </div>
    </>
  )
}
