import React, { useState } from "react";

// Context
export const DataContext = React.createContext({
  page: false,
  musicUrl: false,
  headerScroll: false,
  footerScroll: false,
  blogArray: false,
  blogPosts: {},
  musicArray: false,
});

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    page: false,
    musicUrl: false,
    headerScroll: false,
    footerScroll: false,
    blogArray: false,
    blogPosts: {},
    musicArray: false,
  });

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};
