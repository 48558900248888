import React from "react"

// Context
import { DataProvider } from "./src/context/data"

// Components
import NavMenu from "./src/components/nav/new-nav"
import Footer from "./src/components/footer/new-footer"
import Layout from "./src/components/layout"
import Main from "./src/components/main/main"
import Cursor from "./src/components/cursor/cursor"
//import MusicPlayer from "./src/components/music-player/music-player"

// Wraps every page in a component
const wrapRootElement = ({ element, props }) => {
  return (
    <DataProvider>
    <Main>
      {element}
      <Footer />
      <NavMenu />
      <Cursor />
      {/* <MusicPlayer /> */}
      </Main>
    </DataProvider>
  )
}

const wrapPageElement = ({ element, props }) => {
  return (
    <>
        <div id="main-wrapper">
          <Layout {...props}>{element}</Layout>
        </div>
    </>
  )
}

export { wrapRootElement, wrapPageElement }
