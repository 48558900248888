import React from "react"

// CSS
import "./cursor.scss"

export default function cursor() {
    return (
        <div className="cursor"></div>
    )
}
