import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import Twitter from "./twitter"
import Facebook from "./facebook"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    url,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${url}${image || defaultImage}`,
    url: `${url}${pathname}`,
  }

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={seo.title.length + 18 > 60 ? "" : titleTemplate}
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <Facebook
        pageUrl={seo.url}
        type={article ? "article" : null}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
      <Twitter
        username={twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        url: url
        twitterUsername
        author: author
      }
    }
  }
`

export default SEO
