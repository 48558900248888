import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const Image = ({ src, cssClass, ...rest }) => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  )

  // Send an Gatsby Child Image Sharp Object
  if (src.childImageSharp)
    return (
      <GatsbyImage
        image={src.childImageSharp.gatsbyImageData}
        durationFadeIn={100}
        {...rest}
      />
    )
  
  // If no match is found in /image/ return null
  if (!match) return null

  // If image found in /image/ folder
  const { node: { childImageSharp, publicURL, extension } = {} } = match
  if (!childImageSharp || extension === "svg")
    return <img src={publicURL} {...rest} />
  return (
    <GatsbyImage
      image={childImageSharp.gatsbyImageData}
      durationFadeIn={100}
      {...rest}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.length > 0 ? PropTypes.string : "",
}

export default Image
