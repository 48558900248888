export const HeadlineText = type => {
    switch (type) {
      case "start":
        return "Get in contact"
      case "thank-you":
        return "Thank you"
    }
  }
  
  export const StateReducer = (state, action) => {
    switch (action.type) {
      case "field": {
        state[action.field] = action.value
        return { ...state }
      }
      default:
        return { ...state }
    }
  }
  