export default function contactForm(data) {
  return new Promise(async (resolve, reject) => {
    const headers = new Headers({
      "Access-Control-Allow-Origin": "*"
  })
  const request = new Request(
    "https://europe-west1-personal-website-9cd0a.cloudfunctions.net/form-process",
    {
      method: "POST",
      headers: headers,
      mode: "cors",
      cache: "default",
      body: JSON.stringify(data),
    }
  )

  await fetch(request)
    .then(response => response.text())
    .then(data => {
      console.log("Success:", data)
      resolve()
    })
    .catch(error => {
      console.error("Error:", error)
      reject()
    })
  })
}
  