import React from "react"

import ContactForm from "../../../fetch/contactForm"
import Image from "../../image/image"
import ConnectionFailed from "../connection-failed"

export default function Start(props) {
  const { page, state, stateDispatch, SwitchPage, setOpenForm, href } = props
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    const object = state;
    object.page = href
    object.pageName = "Contact Form"
    ContactForm(object).then(() => {
      SwitchPage(e, "thank-you")
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      setError(true)
    })
  }

  return (
    <>
    <form onSubmit={e => submitForm(e)}>
      <div className={page === "start" ? "text-field show" : "text-field hide"}>
        <p>What's your name?</p>
        <input
          type="text"
          placeholder="John Doe"
          value={state.name}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "name",
            })
          }
          required
        />
      </div>
      <div className={page === "start" ? "text-field show" : "text-field hide"}>
        <p>What's your business email?</p>
        <input
          type="email"
          placeholder="john.doe@email.com"
          value={state.email}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "email",
            })
          }
          required
        />
      </div>
      <div className={page === "start" ? "text-field show" : "text-field hide"}>
        <p>Your message</p>
        <textarea
          type="text"
          placeholder="Write a sentence or two"
          value={state.message}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "message",
            })
          }
          required
        />
      </div>
      <div className={page === "start" ? "checkbox show" : "checkbox hide"}>
        <input
          id="newsletter"
          type="checkbox"
          checked={state.newsletter}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.checked,
              field: "newsletter",
            })
          }
        />
        <p>Click to get marketing goodies to your inbox</p>
        <label htmlFor="newsletter">
          <div className="box-text">
            I'll send you blog post, scripts, automation and free stuff directly
            to your inbox. <br />
            You can unsubscribe at any time and I'll have no time to spam you.
          </div>
        </label>
      </div>
      <div className={page === "start" ? "checkbox show" : "checkbox hide"}>
        <input
          id="consent"
          type="checkbox"
          checked={state.consent}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.checked,
              field: "consent",
            })
          }
          required
        />
        <p>
          <span>
            Do you accept that I contact you and save your information? *
          </span>
        </p>
        <label htmlFor="consent">
          <div className="box-text">
            I need to save your information to deliver the report and contact
            you.
            <br />
            I'm using Google to process your information and Hubspot to save it.
          </div>
        </label>
      </div>
      <button
        id={loading ? "loading" : ""}
        className={
          page === "start" ? "submit-button show" : "submit-button hide"
        }
        type="submit"
      >
        {loading ? <div className="loading-icon"><Image src="loading.svg" /></div> : "Send message"}
      </button>
      <button
        type="button"
        className={
          page === "start"
            ? "submit-button show back"
            : "submit-button hide back"
        }
        onClick={() => setOpenForm(false)}
      >
        Close window
      </button>
      <ConnectionFailed error={error} setOpenForm={setOpenForm} />
    </form>
    </>
  )
}
