import React from "react"
import "./footer.scss"

// Gatsby
import { Link, navigate } from "gatsby"

// Components
import ContactForm from "../forms/contact-form/contact-form"
import ScrollAnimation from "react-animate-on-scroll"

export default function Footer() {
  // Link
  const goTo = url => {
    navigate(url)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  // Contact form
  const [openForm, setOpenForm] = React.useState(false)
  const toggleForm = type => {
    setTimeout(() => {
      setOpenForm(type)
    }, 0)
  }

  return (
    <>
      <ContactForm openForm={openForm} setOpenForm={setOpenForm} href={"https://www.johanneseklund.com/"} />
      <div id="footer">
        <div className="text-area">
          <div className="title-header">
            <div className="text-line">
              <h2>Johannes</h2>
            </div>
            <div className="text-line">
              <h2>Eklund</h2>
            </div>
          </div>
          <div className="description-header">
            <div className="text-line">
              <h3>
                Digital strategiest that is used to help some of the largest
                nordic retailers and brands grow and scale their digital
                business.
              </h3>
            </div>
          </div>
          <ScrollAnimation
            offset={0}
            animateIn="fade-in-from-bottom"
            animateOnce={true}
            delay={0}
          >
            <div className="cta" onClick={toggleForm}>
              <h4>Get in Contact</h4>
            </div>
          </ScrollAnimation>
        </div>
        <div className="navigation">
          <ScrollAnimation
            offset={0}
            animateIn="fade-in-from-bottom"
            animateOnce={true}
            delay={0}
          >
            <h4>World Rank Achivements</h4>
          </ScrollAnimation>
          <ul>
            <li>
                <a href="https://technicalseo.expert/robots.txt" target="_blank" rel="noopener">Top #28 Technical SEO Expert</a>
            </li>
            <li>
                <a href="https://seopirates.ralgar.one/robots.txt" target="_blank" rel="noopener">Top #28 SEO Pirate</a>
            </li>
          </ul>
          <div className="disclaimer">
            Privacy Policy ∘ Disclaimer ∘ Website created by © Johannes Eklund{" "}
            {new Date().getFullYear().toString()}
          </div>
        </div>
      </div>
    </>
  )
}
