import React from "react"

// CSS
import "./layout.scss"
import "../scss/blog-post.scss"
import "../scss/blog.scss"
import "../scss/branding-report.scss"
import "../scss/coaching.scss"
//import "../scss/index.scss"
import "../scss/full-page.scss"
import "../scss/music.scss"
import "../scss/software.scss"
//import "../scss/responsive.scss"
import "../scss/animations.scss"
import "../scss/branding-report.scss"
import "../scss/form.scss"
import "../scss/buttons.scss"
import "../scss/breadcrumbs.scss"

// SEO
import SEO from "../seo/main"

import FirebaseMessage from "./firebase-msg/firebase-msg"

export default function PageIndex({ children }) {

  return (
    <>
      <SEO />
      <FirebaseMessage />
      {children}
    </>
  )
}

// {data && ExcludePageArray.includes(data.page) ? (
//   <><SEO/>{children}</>
// ) : (
//   <><SEO/>{children}</>
// )}
