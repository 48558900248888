import React, { useContext } from "react"
import { navigate } from "gatsby"

// Mobile Menu
import MobileMenu from "../nav-mobile/new-mobile-nav"

// CSS
import "./nav.scss"

// Context
import { DataContext } from "../../context/data"
import ContactForm from "../forms/contact-form/contact-form"

export default function Header() {
  const { data } = useContext(DataContext)

  const goTo = url => {
    navigate(url)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // Fix bug with cursor disappearing when changing page
    const cursor = document.querySelector(".cursor");
    cursor.classList.remove("hide");
  }

  // Contact form
  const [openForm, setOpenForm] = React.useState(false)
  const toggleForm = type => {
    setTimeout(() => {
      setOpenForm(type)
    }, 0)
  }

  return (
    <>
      <ContactForm openForm={openForm} setOpenForm={setOpenForm} href={"https://www.johanneseklund.com/"} />
      <div id="nav-bar" className={data.headerScroll ? "small" : "large"}>
        <div className="nav-wrapper">
          <div className="nav-item title" onClick={() => goTo("/")}>
            <h1>Johannes Eklund</h1>
          </div>
          <div
            className="nav-button"
            onClick={toggleForm}
            data-cursor-hover
          >
            Contact
          </div>
        </div>
      </div>
    </>
  )
}
